import PartnerCard from '@components/PartnerCard'
import {
  LinkButton,
  Modal,
  Radio,
  Stepper,
  Tag,
  Typography,
  Button,
  useMediaQuery,
  Skeleton,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import {
  BoxAdornmentStyled,
  BundleContainerStyled,
  ModalContentStyled,
  PriceContainerStyled,
  StepperContainerStyled,
  TagsContainerStyled,
} from './styled'
import Space from '@components/Space'
import FlexBox from '@components/FlexBox'
import { useBundlePartnersModal } from './useBundlePartnersModal'
import FlexBoxItem from '@components/FlexBox/FlexBoxItem'

type BundlePartnersModalType = {
  adminEmail: string
  handleCreateLead: ({ partnerId }: { partnerId: string }) => void
  isOpen: boolean
  onClose: () => void
  isContactDataLoading: boolean
  bundlePartners?: {
    name: string
    bundles?: {
      id: string
      name: string
      nominalPrice: string
      price: string
      partners: {
        name: string
        description: string
        image: string
        categories: string[]
      }[]
    }[]
  }
}

const BundlePartnersModal = ({
  isOpen,
  onClose,
  isContactDataLoading,
  bundlePartners,
  adminEmail,
  handleCreateLead,
}: BundlePartnersModalType) => {
  const theme = useTheme()
  const {
    activeStep,
    bundleInfo,
    checkedBundleId,
    handleChangeBundle,
    handleClose,
    handleConfirm,
    handleGoBack,
  } = useBundlePartnersModal(bundlePartners, handleCreateLead, onClose)
  const screenHasSmallHeight = useMediaQuery('(max-height: 768px)')

  return (
    <Modal.Root size="md" open={isOpen} onClose={handleClose} showClose={true}>
      <>
        <StepperContainerStyled>
          <Stepper
            activeStep={activeStep}
            steps={['Escolha o seu pacote', 'Detalhes do pacote']}
          />
        </StepperContainerStyled>
        <Space y="xs3" />
        <Modal.Header
          title={
            activeStep === 0
              ? bundlePartners?.name || ''
              : bundleInfo?.name || ''
          }
        />

        <ModalContentStyled screenHasSmallHeight={screenHasSmallHeight}>
          {activeStep === 0 && (
            <>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[20]}
                weight={700}
              >
                Pacotes
              </Typography>
              <Space y="xs4" />
              {bundlePartners?.bundles?.map((bundle) => (
                <FlexBox flexDirection="column" key={bundle.id}>
                  <Space y="xs3" />
                  <BoxAdornmentStyled
                    leftAdornment={
                      <Radio
                        key={bundle.id}
                        value={bundle.id}
                        checked={checkedBundleId === bundle.id}
                        onChange={(event) => {
                          handleChangeBundle(event.target.value)
                        }}
                      />
                    }
                    description={
                      <FlexBox>
                        <TagsContainerStyled>
                          {bundle.partners.map((partner) => (
                            <Tag variant="gray" disabled>
                              {partner.name}
                            </Tag>
                          ))}
                        </TagsContainerStyled>
                        <PriceContainerStyled>
                          <div>
                            <Typography
                              variant="caption"
                              variantColor={theme.colors.neutral[30]}
                              weight={400}
                            >
                              Mensalidade / vida:
                            </Typography>
                          </div>
                          <FlexBox>
                            <Typography
                              variant="body4"
                              variantColor={theme.colors.neutral[50]}
                              weight={600}
                            >
                              <s>{bundle.nominalPrice}</s>
                            </Typography>
                            <Typography
                              variant="body4"
                              variantColor={theme.colors.secondary[50]}
                              weight={700}
                            >
                              {bundle.price}
                            </Typography>
                          </FlexBox>
                        </PriceContainerStyled>
                      </FlexBox>
                    }
                    title={
                      <Typography
                        variant="body3"
                        weight={600}
                        variantColor={theme.colors.neutral[30]}
                      >
                        {bundle.name}
                      </Typography>
                    }
                  />
                </FlexBox>
              ))}
            </>
          )}

          {activeStep === 1 && (
            <>
              <Typography
                variant="body3"
                variantColor={theme.colors.neutral[20]}
                weight={700}
              >
                Parceiros
              </Typography>
              <Space y="xs3" />
              <BundleContainerStyled>
                {bundleInfo?.partners.map((partner, index) => (
                  <PartnerCard
                    key={`${index}-${partner.name}`}
                    title={partner.name}
                    description={partner.description}
                    image={partner.image}
                    categories={partner.categories}
                  />
                ))}
              </BundleContainerStyled>
              <Space y="xs3" />
              <FlexBox alignItems="center" flexDirection="column">
                <FlexBox flexDirection="row" alignItems="center" gap="xs5">
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[30]}
                    weight={400}
                  >
                    Mensalidade / vida:
                  </Typography>
                  <Typography
                    variant="body3"
                    variantColor={theme.colors.neutral[50]}
                    weight={600}
                  >
                    <s>{bundleInfo.nominalPrice}</s>
                  </Typography>
                  <Typography
                    variant="body2"
                    variantColor={theme.colors.secondary[50]}
                    weight={700}
                  >
                    {bundleInfo.price}
                  </Typography>
                </FlexBox>
                <Space y="xs3" />
                <FlexBox flexDirection="row" alignItems="center" gap="xs3">
                  <Typography
                    variant="body4"
                    variantColor={theme.colors.neutral[30]}
                    weight={400}
                  >
                    Ao prosseguir vamos entrar em contato com você no e-mail:
                  </Typography>
                  {isContactDataLoading ? (
                    <Skeleton variant="text" width="180px" />
                  ) : (
                    <Typography
                      variant="body4"
                      variantColor={theme.colors.secondary[50]}
                      weight={600}
                    >
                      {adminEmail}
                    </Typography>
                  )}
                </FlexBox>
              </FlexBox>
            </>
          )}
        </ModalContentStyled>
        <Modal.Footer>
          <FlexBox
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <FlexBoxItem>
              <LinkButton
                variant="secondary"
                onClick={
                  activeStep === 0 ? () => onClose() : () => handleGoBack()
                }
              >
                {activeStep === 0 ? 'Cancelar' : 'Voltar'}
              </LinkButton>
            </FlexBoxItem>
            <FlexBoxItem>
              <Button
                variant="primary"
                size="large"
                onClick={handleConfirm}
                disabled={!checkedBundleId}
                loading={activeStep === 1 && isContactDataLoading}
              >
                {activeStep === 0 ? 'Continuar' : 'Fale com nosso comercial'}
              </Button>
            </FlexBoxItem>
          </FlexBox>
        </Modal.Footer>
      </>
    </Modal.Root>
  )
}

export default BundlePartnersModal
