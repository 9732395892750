import { PermissionEnum, VerifyPermission } from '@components/VerifyPermission'
import Marketplace from '../pages/Marketplace'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ErrorPage } from '../pages/ErrorPage'

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/marketplace"
          element={
            <VerifyPermission
              component={<Marketplace />}
              permission={PermissionEnum.CORE_CONTRACT_PRODUCTS}
            />
          }
        />
        <Route path="/marketplace/error-page" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
