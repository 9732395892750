import styled from 'styled-components'

const BannerContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
`

export { BannerContainer }
