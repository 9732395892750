import styled from 'styled-components'

export const ContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const FilterTabContainerStyled = styled.div`
  display: flex;
  justify-content: center;
`

export const PageLoadingContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const PartnerLoadingContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
`

export const NoPartnersFoundContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
`

export const PartnerSearchContainerStyled = styled.div`
  width: 320px;
  margin-inline: auto;
`
