import styled from 'styled-components'

const ContainerStyled = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  position: relative;
`

const HeaderStyled = styled.div`
  border-top-right-radius: ${({ theme }) => theme.borders.radius.xl};
  border-top-left-radius: ${({ theme }) => theme.borders.radius.xl};
  padding: ${({ theme }) => theme.spacings.xs1}
    ${({ theme }) => theme.spacings.xs1};
`

const ChildrenStyled = styled.div`
  padding: ${({ theme }) => theme.spacings.xs1}
    ${({ theme }) => theme.spacings.xs1};
`

const FooterStyled = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacings.xs1}
    ${({ theme }) => theme.spacings.xs1};
`

const ImageStyled = styled.img`
  border-top-right-radius: ${({ theme }) => theme.borders.radius.xl};
  border-top-left-radius: ${({ theme }) => theme.borders.radius.xl};
`

export {
  ContainerStyled,
  ChildrenStyled,
  HeaderStyled,
  FooterStyled,
  ImageStyled,
}
