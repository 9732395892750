import { Tag } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const ImageContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs3};
  display: flex;
  align-items: center;

  img {
    width: ${({ theme }) => theme.spacings.m};
    height: ${({ theme }) => theme.spacings.s};
  }
`
const TagStyled = styled(Tag)`
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  height: ${({ theme }) => theme.spacings.xs1};
  padding-top: ${({ theme }) => theme.spacings.xs5};
  padding-bottom: ${({ theme }) => theme.spacings.xs5};
`

const ContainerStyled = styled.div`
  width: 100%;
`

const ImageStyled = styled.img`
  object-fit: scale-down;
`

export { ContainerStyled, ImageContainerStyled, TagStyled, ImageStyled }
