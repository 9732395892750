import {
  segmentEventTracking,
  segmentPageTracking,
} from '@flash-tecnologia/hros-web-utility'
import { useCallback } from 'react'

type EventProps = {
  name: string
  params?: Record<string, string>
}

const MODULE_NAME = 'marketplace'
const BUSINESS_UNIT = 'benefits'

export function useEventTracking() {
  const buildTrackProps = useCallback((event: EventProps) => {
    return {
      businessUnit: BUSINESS_UNIT,
      module: MODULE_NAME,
      name: event.name,
      params: {
        ...event?.params,
      },
    }
  }, [])

  const trackEvent = useCallback(
    (event: EventProps) => {
      const trackEventProps = buildTrackProps(event)

      return segmentEventTracking(trackEventProps)
    },
    [buildTrackProps]
  )

  const trackPage = useCallback(
    (event: EventProps) => {
      const trackPageProps = buildTrackProps(event)

      return segmentPageTracking(trackPageProps)
    },
    [buildTrackProps]
  )

  return {
    trackEvent,
    trackPage,
  }
}
