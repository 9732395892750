import {
  Button,
  LinkButton,
  Modal,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import {
  FooterStyled,
  ImageContainerStyled,
  ReferencesContainerStyled,
  TitleContainerStyled,
  ImageStyled,
} from './styled'
import Space from '@components/Space'

type PartnershipModalProps = {
  isOpen: boolean
  onClose: () => void
  shouldShowLoadingOnButton: boolean
  handlePartnershipModalSubmit: (
    id: string,
    type: string | null,
    url: string
  ) => void
  partnership: {
    id: string
    image: string
    name: string
    card: {
      tag?: string
      categories: string[]
      caption: string
      description: string
    }
    details?: {
      caption: string
      contentHeader: string
      content: string
      references: Array<{
        title: string
        link: string
      }>
      footer: string
      action: {
        type: null | string
        title: string
        url: string
      }
    }
  }
}

const PartnershipModal = ({
  isOpen,
  onClose,
  shouldShowLoadingOnButton,
  partnership,
  handlePartnershipModalSubmit,
}: PartnershipModalProps) => {
  const theme = useTheme()

  return (
    <Modal.Root size="md" open={isOpen} onClose={onClose} showClose>
      <>
        <Modal.Header
          title={
            (
              <TitleContainerStyled>
                <ImageContainerStyled>
                  <ImageStyled src={partnership.image} alt={partnership.name} />
                </ImageContainerStyled>
                <Space y="xs4" />
                {partnership.name}
              </TitleContainerStyled>
            ) as unknown as string
          }
          description={partnership.details?.caption}
        />

        <Modal.Content>
          <Typography
            weight={700}
            variant="body3"
            variantColor={theme.colors.neutral[50]}
          >
            {partnership.details?.contentHeader}
          </Typography>
          <Space y="xs3" />
          {partnership.details?.content.split(/\r?\n/).map((item, index) => (
            <Typography
              key={index}
              variantColor={theme.colors.neutral[50]}
              variant="body3"
              weight={400}
            >
              {item}
            </Typography>
          ))}
          <Space y="xs" />
          <ReferencesContainerStyled>
            {partnership.details?.references.map((item, index) => (
              <LinkButton key={index} variant="primary">
                <a href={item.link} target="_blank">
                  {item.title}
                </a>
              </LinkButton>
            ))}
          </ReferencesContainerStyled>
          <Space y="xs3" />
          {partnership.details?.footer && (
            <Typography variant="body3" variantColor={theme.colors.neutral[50]}>
              *{partnership.details?.footer}
            </Typography>
          )}
        </Modal.Content>
        <FooterStyled>
          <Modal.Footer>
            <Button
              variant="primary"
              size="large"
              loading={shouldShowLoadingOnButton}
              onClick={() =>
                handlePartnershipModalSubmit(
                  partnership.id,
                  partnership.details?.action.type ?? null,
                  partnership.details?.action.url ?? ''
                )
              }
            >
              {partnership.details?.action.title}
            </Button>
          </Modal.Footer>
        </FooterStyled>
      </>
    </Modal.Root>
  )
}

export default PartnershipModal
