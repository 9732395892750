const partnershipType = {
  FLASH_PARTNER: 'flash_partner',
  OTHER_PARTNER: 'other_partner',
}

const partnershipRedirect = {
  LEAD: 'lead',
  REDIRECT: 'redirect',
}

export { partnershipRedirect, partnershipType }
