import styled from 'styled-components'

const ContainerStyled = styled.div`
  border-radius: ${({ theme }) => theme.borders.radius.xl};
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  display: flex;
  padding: ${({ theme }) => theme.spacings.xs2};
  gap: ${({ theme }) => theme.spacings.xs4};
  align-items: center;
`

const ImageContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[100]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs3};

  img {
    align-self: center;
    width: ${({ theme }) => theme.spacings.s};
    height: ${({ theme }) => theme.spacings.s};
  }
`
const TagsContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`

const ImageStyled = styled.img`
  object-fit: scale-down;
`

export {
  ContainerStyled,
  ImageContainerStyled,
  TagsContainerStyled,
  ImageStyled,
}
