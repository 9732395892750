const pages = {
  MARKETPLACE: 'marketplace_page',
}

const events = {
  MARKETPLACE_PRODUCT_DETAIL_CLICKED: 'marketplace_product_details_clicked',
  MARKETPLACE_PRODUCT_CTA_CLICKED: 'marketplace_product_cta_clicked',
}

export { pages, events }
