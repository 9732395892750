import PackageCard from '@components/PackageCard'
import { Button, Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'
import {
  ContainerStyled,
  ImageContainerStyled,
  ImageStyled,
  TagStyled,
} from './styled'
import Space from '@components/Space'
import FlexBox from '@components/FlexBox'

type HighlightCardType = {
  advertisement: {
    name: string
    image: string
    card: {
      backgroundImage?: string
      caption: string
      tag?: string
      description: string
      categories: string[]
      footer?: string
    }
    partnershipData?: {
      id?: string
      activatedAt?: string
      active?: boolean
      fee?: number
      lives?: number
      productId?: string
      productName?: string
    }
  }
  onClick: () => void
}

const HighlightCard = ({ advertisement, onClick }: HighlightCardType) => {
  const theme = useTheme()

  return (
    <PackageCard
      width={380}
      height={400}
      headerBackgroundImage={advertisement.card.backgroundImage}
      children={
        <>
          <FlexBox gap="xs3" alignItems="flex-start">
            <ImageContainerStyled>
              <ImageStyled src={advertisement.image} alt={advertisement.name} />
            </ImageContainerStyled>
            <ContainerStyled>
              <FlexBox justifyContent="space-between">
                <FlexBox flexDirection="column">
                  <Typography
                    variant="body3"
                    color={theme.colors.neutral[30]}
                    weight={600}
                  >
                    {advertisement.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={theme.colors.neutral[30]}
                    weight={400}
                  >
                    {advertisement.card.caption}
                  </Typography>
                </FlexBox>
                <ContainerStyled>
                  <FlexBox
                    flexDirection="column"
                    flexWrap="wrap-reverse"
                    gap="xs4"
                  >
                    {advertisement.card.tag && (
                      <TagStyled variant="primary" disabled>
                        {advertisement.card.tag}
                      </TagStyled>
                    )}
                    {advertisement.card.categories.map((category, idx) => (
                      <TagStyled key={idx} variant="gray" disabled>
                        {category}
                      </TagStyled>
                    ))}
                  </FlexBox>
                </ContainerStyled>
              </FlexBox>
            </ContainerStyled>
          </FlexBox>
          <Space y="xs3" />
          <Typography
            variant="body4"
            color={theme.colors.neutral[30]}
            weight={400}
          >
            {advertisement.card.description}
          </Typography>
        </>
      }
      footer={
        <FlexBox justifyContent="space-between" alignItems="center">
          <div>
            <Tag variant="pink" disabled>
              {advertisement.card.footer}
            </Tag>
          </div>
          <Button variant="primary" size="small" onClick={onClick}>
            {advertisement.partnershipData?.active
              ? 'Ver detalhes'
              : 'Saiba mais'}
          </Button>
        </FlexBox>
      }
    />
  )
}

export default HighlightCard
