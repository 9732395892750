import { trpc } from '@api/client'
import { useDispatchToast } from '@utils/hooks/useDispatchToast'
import { useEventTracking } from '@utils/hooks/useEventTracking'
import { useEffect, useMemo, useState } from 'react'
import {
  partnershipRedirect,
  partnershipType,
} from './constants/partnershipType'
import { events, pages } from './constants/tracking'
import { AdvertisementType } from 'bff'
import {
  getFromLS,
  setInLS,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'
import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'

const LEAD_CACHE_LS_KEY = 'benefits-marketplace-ctas-clicked'

export const useMarketplace = () => {
  const { selectedCompany } = useSelectedCompany()
  const tracking = useEventTracking()

  const [openBundlePartnersModal, setOpenBundlePartnersModal] =
    useState<boolean>(false)
  const [openPartnershipModal, setOpenPartnershipModal] =
    useState<boolean>(false)
  const [openPartnershipDrawer, setOpenPartnershipDrawer] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedTab, setSelectedTab] = useState(0)

  const [partnerModalInfo, setPartnerModalInfo] = useState<AdvertisementType>()
  const [partnershipDrawerInfo, setPartnershipDrawerInfo] =
    useState<AdvertisementType>()
  const [bundlePartnerModalInfo, setBundlePartnerModalInfo] =
    useState<AdvertisementType>()

  const filterTabItemNames = [
    'Todos',
    'Recursos Humanos',
    'Financeiro',
    'Tecnologia',
  ]

  const filterTabItems = useMemo(
    () =>
      filterTabItemNames.map((tabName) => ({
        label: tabName,
        component: undefined,
      })),
    []
  )

  const {
    data: advertisements,
    isLoading: isPartnersLoading,
    refetch: refetchPartners,
  } = trpc.getAdvertisements.useQuery(
    {
      filter: {
        name: search,
        category: filterTabItemNames[selectedTab],
      },
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const {
    data: companyPartnerships,
    isLoading: isCompanyPartnershipsLoading,
    refetch: refetchCompanyPartnerships,
  } = trpc.getCompanyPartnerships.useQuery(
    { companyId: selectedCompany?.id },
    {
      refetchOnWindowFocus: false,
    }
  )

  const otherPartners = useMemo(
    () =>
      advertisements?.filter(
        ({ type }) => type === partnershipType.OTHER_PARTNER
      ),
    [advertisements]
  )

  const { data: highlightedPartners, isLoading: isHighlightedPartnersLoading } =
    trpc.getAdvertisements.useQuery(
      { filter: { highlight: true } },
      {
        refetchOnWindowFocus: false,
      }
    )

  const flashPartners = useMemo(() => {
    const flashPartnersWithPartnershipsInformations = advertisements
      ?.filter(({ type }) => type === partnershipType.FLASH_PARTNER)
      ?.map((partner) => {
        if (!companyPartnerships) {
          return partner
        }

        const companyPartnership = companyPartnerships?.find(
          ({ productId, partners }) =>
            partner.bundles?.length
              ? partner?.productIds?.find(
                  (_productId) => productId === _productId
                )
              : partner?.partnerIds?.some((partnerId) =>
                  partners.includes(partnerId)
                )
        )

        const partnerships = {
          ...partner,
          partnershipData: companyPartnership,
        }

        return partnerships
      })

    return flashPartnersWithPartnershipsInformations
  }, [advertisements])

  const highlightedPartnersWithPartnershipsInformations =
    highlightedPartners?.map((partner) => {
      if (!companyPartnerships) {
        return partner
      }

      const companyPartnership = companyPartnerships?.find(
        ({ productId, partners }) =>
          partner.bundles?.length
            ? partner?.productIds?.find(
                (_productId) => productId === _productId
              )
            : partner?.partnerIds?.some((partnerId) =>
                partners.includes(partnerId)
              )
      )

      const partnerships = {
        ...partner,
        partnershipData: companyPartnership,
      }

      return partnerships
    })

  const {
    data: contactData,
    refetch: fetchContactData,
    isLoading: isContactDataLoading,
  } = trpc.getAdmin.useQuery(undefined, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  useEffect(() => {
    tracking.trackPage({
      name: pages.MARKETPLACE,
    })
  }, [])

  const adminEmail = contactData?.email || ''

  const { dispatchToast } = useDispatchToast()

  const { mutate: createLeadMutation } = trpc.createLead.useMutation({
    mutationKey: ['createBundleLead'],
    onError: () => {
      dispatchToast({
        description: 'Tente novamente mais tarde.',
        title: 'Erro ao enviar seu pedido de contato.',
        type: 'error',
      })
    },
    onSuccess: (_data, { partnerId }) => {
      const cachedLeads = getFromLS(LEAD_CACHE_LS_KEY) || {}

      setInLS({
        key: LEAD_CACHE_LS_KEY,
        value: { ...cachedLeads, [partnerId]: dayjs().toISOString() },
      })

      dispatchToast({
        content: `Em breve nosso time de atendimento irá entrar em contato através do e-mail ${adminEmail}`,
        type: 'success',
      })
    },
  })

  const handleClickBundlePartner = (advertisement) => {
    if (!adminEmail) {
      fetchContactData()
    }
    if (advertisement.type !== 'bundle') {
      setOpenPartnershipModal(true)
      setPartnerModalInfo(advertisement)
      return
    }
    setBundlePartnerModalInfo(advertisement)
    setOpenBundlePartnersModal(true)
  }

  const trackModalCtaClicked = (product_id: string) =>
    tracking.trackEvent({
      name: events.MARKETPLACE_PRODUCT_CTA_CLICKED,
      params: {
        product_id,
      },
    })

  const trackModalDetailsClicked = (product_id: string) =>
    tracking.trackEvent({
      name: events.MARKETPLACE_PRODUCT_DETAIL_CLICKED,
      params: {
        product_id: product_id || '',
      },
    })

  const handlePartnershipModalSubmit = (
    id: string,
    type: string | null,
    url: string
  ) => {
    switch (type) {
      case partnershipRedirect.LEAD:
        handleCreateLead({ partnerId: id })
        break
      case partnershipRedirect.REDIRECT:
        setTimeout(() => {
          window.open(url, '_blank')
        }, 500)
        break
      default:
        break
    }
    setOpenPartnershipModal(false)
  }

  const handleClickPartnerShip = (partner) => {
    if (!adminEmail) {
      fetchContactData()
    }
    setOpenPartnershipModal(true)
    setPartnerModalInfo(partner)
    trackModalDetailsClicked(partner?.id || '')
  }

  const handlePartnershipDetailsClick = (partner) => {
    setOpenPartnershipDrawer(true)
    setPartnershipDrawerInfo(partner)
  }

  const handleCreateLead = ({ partnerId }: { partnerId: string }) => {
    const shouldBlockLeadCreation =
      checkCachedLeadExpirationDateBlock(partnerId)

    if (shouldBlockLeadCreation) {
      dispatchToast({
        title: '',
        description:
          'Já registramos seu interesse nesse parceiro, aguarde o contato do nosso time de atendimento',
        type: 'warning',
      })
      setOpenBundlePartnersModal(false)
      return
    }

    createLeadMutation({
      partnerId: partnerId || '',
      name: contactData?.name || '',
      email: contactData?.email || '',
      phone: contactData?.phoneNumber || '',
      companyId: selectedCompany?.id || '',
    })
    setOpenBundlePartnersModal(false)
  }

  const checkCachedLeadExpirationDateBlock = (partnerId: string) => {
    const cachedLeads: Record<string, string> | undefined =
      getFromLS(LEAD_CACHE_LS_KEY)
    const cachedLeadDate = cachedLeads?.[partnerId]

    const today = dayjs()
    const isCachedLeadExpired = today.isBefore(
      dayjs(cachedLeadDate).add(1, 'month')
    )

    return cachedLeadDate && isCachedLeadExpired
  }

  return {
    adminEmail,
    bundlePartnerModalInfo,
    filterTabItems,
    flashPartners,
    handleClickBundlePartner,
    handleClickPartnerShip,
    handleCreateLead,
    handlePartnershipModalSubmit,
    highlightedPartners: highlightedPartnersWithPartnershipsInformations,
    isContactDataLoading,
    isHighlightedPartnersLoading,
    isPartnersLoading,
    openBundlePartnersModal,
    openPartnershipModal,
    otherPartners,
    partnerModalInfo,
    refetchPartners,
    companyPartnerships,
    refetchCompanyPartnerships,
    isCompanyPartnershipsLoading,
    openPartnershipDrawer,
    setOpenPartnershipDrawer,
    partnershipDrawerInfo,
    handlePartnershipDetailsClick,
    search,
    selectedTab,
    setOpenBundlePartnersModal,
    setOpenPartnershipModal,
    setSearch,
    setSelectedTab,
    trackModalCtaClicked,
    trackModalDetailsClicked,
  }
}
