import { BannerContainer } from './styled'

const Header = () => {
  return (
    <BannerContainer>
      <img
        src="https://images.flashapp.com.br/Front-Assets/Images/Partners/Hub__0025_Background_Flash.png"
        alt="Flash Encanta"
      />
    </BannerContainer>
  )
}

export default Header
