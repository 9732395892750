import PackageCard from '@components/PackageCard'
import {
  HeaderStyled,
  ImageContainerStyled,
  TagStyled,
  TagsContainerStyled,
  ImageStyled,
} from '@components/PartnershipCard/styled'
import Space from '@components/Space'
import { LinkButton, Typography } from '@flash-tecnologia/hros-web-ui-v2'

type PartnershipCardProps = {
  id: string
  image: string
  name: string
  card: {
    tag?: string
    categories: string[]
    caption: string
    description: string
  }
  onClick: () => void
  partnershipData?: {
    id?: string
    activatedAt?: string
    active?: boolean
    fee?: number
    lives?: number
    productId?: string
    productName?: string
  }
}

const PartnershipCard = ({
  card,
  image,
  name,
  onClick,
  partnershipData,
}: PartnershipCardProps) => {
  return (
    <PackageCard
      header={
        <HeaderStyled>
          <ImageContainerStyled>
            <ImageStyled src={image} />
          </ImageContainerStyled>
          <TagsContainerStyled>
            {card.tag && (
              <>
                <TagStyled variant="pink" disabled>
                  {card.tag}
                </TagStyled>
                <Space y="xs4" />
              </>
            )}
            {card.categories.map((category, idx) => (
              <div key={idx}>
                <TagStyled variant="gray" disabled>
                  {category}
                </TagStyled>
                <Space y="xs4" />
              </div>
            ))}
          </TagsContainerStyled>
        </HeaderStyled>
      }
      height={370}
      width={317}
      footer={
        <LinkButton
          style={{ fontSize: '14px' }}
          variant={'primary'}
          onClick={onClick}
        >
          {partnershipData?.active
            ? `Ver detalhes da contratação`
            : `Saiba mais`}
        </LinkButton>
      }
    >
      <Typography variant="body3" weight={600}>
        {name}
      </Typography>
      <Typography variant="caption" weight={400}>
        {card.caption}
      </Typography>
      <Space y="xs" />
      <Typography variant="body4">{card.description}</Typography>
    </PackageCard>
  )
}

export default PartnershipCard
