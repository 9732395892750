import Space from '@components/Space'
import {
  CircularProgress,
  PageContainer,
  Typography,
  SearchField,
  Tab,
} from '@flash-tecnologia/hros-web-ui-v2'

import { useTheme } from 'styled-components'
import { useMarketplace } from './useMarketplace'
import Header from './Header'
import {
  ContainerStyled,
  FilterTabContainerStyled,
  NoPartnersFoundContainerStyled,
  PageLoadingContainerStyled,
  PartnerLoadingContainerStyled,
  PartnerSearchContainerStyled,
} from './styled'
import BundlePartnersModal from '@components/BundlePartnersModal'
import PartnershipModal from '@components/PartnershipModal'
import HighlightCard from '@components/HighlightCard'
import { AdvertisementList } from './AdvertisementList'
import { partnershipRedirect } from './constants/partnershipType'
import { PartnershipDrawer } from '@components/PartnershipDrawer'

const Marketplace = () => {
  const theme = useTheme()
  const {
    adminEmail,
    bundlePartnerModalInfo,
    filterTabItems,
    flashPartners,
    handleClickBundlePartner,
    handleClickPartnerShip,
    handleCreateLead,
    handlePartnershipModalSubmit,
    highlightedPartners,
    isContactDataLoading,
    isHighlightedPartnersLoading,
    isPartnersLoading,
    openBundlePartnersModal,
    openPartnershipModal,
    openPartnershipDrawer,
    otherPartners,
    partnerModalInfo,
    search,
    selectedTab,
    setOpenBundlePartnersModal,
    setOpenPartnershipModal,
    setOpenPartnershipDrawer,
    handlePartnershipDetailsClick,
    partnershipDrawerInfo,
    setSearch,
    setSelectedTab,
    trackModalCtaClicked,
    trackModalDetailsClicked,
  } = useMarketplace()

  if (isHighlightedPartnersLoading) {
    return (
      <PageLoadingContainerStyled>
        <CircularProgress variant="indeterminate" value={0} />
      </PageLoadingContainerStyled>
    )
  }

  const renderPartnerListSectionContent = () => {
    if (isPartnersLoading) {
      return (
        <PartnerLoadingContainerStyled>
          <CircularProgress variant="indeterminate" value={0} />
        </PartnerLoadingContainerStyled>
      )
    }

    if (!flashPartners?.length && !otherPartners?.length) {
      return (
        <NoPartnersFoundContainerStyled>
          Nenhum parceiro encontrado
        </NoPartnersFoundContainerStyled>
      )
    }

    return (
      <>
        <AdvertisementList
          handleClickPartnerShip={handleClickPartnerShip}
          advertisements={flashPartners}
          handlePartnershipDetailsClick={handlePartnershipDetailsClick}
          title="Experiência Flash"
          subTitle="Tenha descontos especiais e uma gestão centralizada com os parceiros abaixo"
        />
        <AdvertisementList
          handleClickPartnerShip={handleClickPartnerShip}
          advertisements={otherPartners}
          title="Parceiros Flash"
          subTitle="Tenha descontos exclusivos Flash ao contratar diretamente com os parceiros abaixo"
        />
      </>
    )
  }

  return (
    <PageContainer>
      <Space y="s" />
      <Header />
      <Space y="m" />
      <Typography
        variant="headline7"
        variantColor={theme.colors.neutral[30]}
        weight={700}
      >
        Veja nossos destaques:
      </Typography>
      <Space y="s" />
      <ContainerStyled>
        {highlightedPartners?.map((highlightPartner) => (
          <HighlightCard
            key={highlightPartner.id}
            advertisement={highlightPartner}
            onClick={() => {
              if (highlightPartner.partnershipData?.active) {
                handlePartnershipDetailsClick(highlightPartner)
                return
              }
              handleClickBundlePartner(highlightPartner)
              trackModalDetailsClicked(highlightPartner.id)
            }}
          />
        ))}
      </ContainerStyled>
      <Space y="m" />
      <PartnerSearchContainerStyled>
        <SearchField
          label={'Buscar por parceiro'}
          value={search}
          onChange={(e) => setSearch(e?.target?.value)}
        />
      </PartnerSearchContainerStyled>
      <Space y="m" />
      <FilterTabContainerStyled>
        <Tab
          tabItens={filterTabItems}
          onTabChanged={(tabIdx) => setSelectedTab(tabIdx)}
          selected={selectedTab}
        />
      </FilterTabContainerStyled>
      <Space y="s" />
      {renderPartnerListSectionContent()}
      <Space y="m" />
      {bundlePartnerModalInfo && (
        <BundlePartnersModal
          bundlePartners={bundlePartnerModalInfo}
          isOpen={openBundlePartnersModal}
          onClose={() => setOpenBundlePartnersModal(false)}
          isContactDataLoading={isContactDataLoading}
          adminEmail={adminEmail}
          handleCreateLead={({ partnerId }) => {
            trackModalCtaClicked(partnerId)
            handleCreateLead({ partnerId })
          }}
        />
      )}
      {partnerModalInfo && (
        <PartnershipModal
          partnership={partnerModalInfo}
          isOpen={openPartnershipModal}
          onClose={() => setOpenPartnershipModal(false)}
          shouldShowLoadingOnButton={
            isContactDataLoading &&
            partnerModalInfo.details?.action.type === partnershipRedirect.LEAD
          }
          handlePartnershipModalSubmit={(id, type, url) => {
            trackModalCtaClicked(id)
            handlePartnershipModalSubmit(id, type, url)
          }}
        />
      )}
      {openPartnershipDrawer && (
        <PartnershipDrawer
          openPartnershipDrawer={openPartnershipDrawer}
          partnershipDrawerInfo={partnershipDrawerInfo}
          setOpenPartnershipDrawer={setOpenPartnershipDrawer}
        />
      )}
    </PageContainer>
  )
}

export default Marketplace
