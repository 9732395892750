import {
  ContainerStyled,
  ImageContainerStyled,
  TagsContainerStyled,
  ImageStyled,
} from './styled'
import { Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTheme } from 'styled-components'

type PartnerCardType = {
  image: string
  title: string
  description: string
  categories: string[]
}

const PartnerCard = ({
  image,
  title,
  description,
  categories,
}: PartnerCardType) => {
  const theme = useTheme()

  return (
    <ContainerStyled>
      <ImageContainerStyled>
        <ImageStyled src={image} alt={title} aria-hidden="true" />
      </ImageContainerStyled>
      <div>
        <Typography
          weight={700}
          variant="body3"
          variantColor={theme.colors.neutral[30]}
        >
          {title}
        </Typography>
        <Typography
          variant="body4"
          variantColor={theme.colors.neutral[30]}
          weight={400}
        >
          {description}
        </Typography>
        <TagsContainerStyled>
          {categories.map((category, idx) => (
            <Tag key={idx} variant="gray" disabled={true}>
              {category}
            </Tag>
          ))}
        </TagsContainerStyled>
      </div>
    </ContainerStyled>
  )
}

export default PartnerCard
