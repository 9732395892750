import { useState } from 'react'

export const useBundlePartnersModal = (bundle, handleCreateLead, onClose) => {
  const initialBundleInfo = {
    id: '',
    name: '',
    nominalPrice: '',
    price: '',
    partners: [
      {
        name: '',
        description: '',
        image: '',
        categories: [],
      },
    ],
  }
  const [activeStep, setActiveStep] = useState<number>(0)
  const [checkedBundleId, setCheckedBundleId] = useState<string>('')
  const [bundleInfo, setBundleInfo] = useState(initialBundleInfo)

  const handleContinue = () => {
    setActiveStep(1)
  }

  const handleGoBack = () => {
    setActiveStep(0)
  }

  const handleChangeBundle = (bundleId) => {
    setBundleInfo(bundle.bundles.find(({ id }) => id === bundleId))
    setCheckedBundleId(bundleId)
  }

  const handleConfirm = () => {
    if (activeStep === 0) handleContinue()

    if (activeStep === 1) {
      handleCreateLead({ partnerId: checkedBundleId })
      setActiveStep(0)
      setCheckedBundleId('')
    }
  }

  const handleClose = () => {
    onClose()
    setActiveStep(0)
    setCheckedBundleId('')
  }

  return {
    activeStep,
    bundleInfo,
    checkedBundleId,
    handleChangeBundle,
    handleClose,
    handleConfirm,
    handleGoBack,
    setCheckedBundleId,
  }
}
