import { Tag } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
`

const ImageContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs3};

  img {
    align-self: center;
    width: ${({ theme }) => theme.spacings.s};
    height: ${({ theme }) => theme.spacings.s};
  }
`

const TagsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
`
const TagStyled = styled(Tag)`
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
  height: ${({ theme }) => theme.spacings.xs1};
  padding-top: ${({ theme }) => theme.spacings.xs5};
  padding-bottom: ${({ theme }) => theme.spacings.xs5};
`

const ImageStyled = styled.img`
  object-fit: scale-down;
`

export {
  HeaderStyled,
  ImageContainerStyled,
  TagsContainerStyled,
  TagStyled,
  ImageStyled,
}
