import { Navigate } from 'react-router-dom'
import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility'

export enum PermissionEnum {
  CORE_CONTRACT_PRODUCTS = 'core_contract_products',
}

type VerifyPermissionProps = {
  permission: PermissionEnum
  component: JSX.Element
}

export function VerifyPermission({
  permission,
  component,
}: VerifyPermissionProps) {
  const { companies, isAdmin } = usePermissions()
  const companyId = useSelectedCompany()?.selectedCompany?.id

  const companyPermissions =
    companies?.find((company) => company._id === companyId)?.permissions ?? []
  const canView = isAdmin || companyPermissions.includes(permission)

  if (canView) return component
  return <Navigate to={'/home'} />
}
