export const parseCurrency = (
  value: number,
  currency = 'BRL',
  localeString = 'pt-BR'
) => {
  if (isNaN(value)) {
    return 'Valor inválido'
  }

  const options = { style: 'currency', currency }
  return value.toLocaleString(localeString, options)
}
