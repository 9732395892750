import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import Space from '@components/Space'
import { PartnershipCardsContainerStyled } from './styled'
import { useTheme } from 'styled-components'
import PartnershipCard from '@components/PartnershipCard'
import { AdvertisementType, AdvertisementsType } from 'bff'

type AdvertisementListProps = {
  advertisements?: AdvertisementsType
  handleClickPartnerShip: (partner: AdvertisementType) => void
  handlePartnershipDetailsClick?: (partner: AdvertisementType) => void
  title: string
  subTitle: string
}

export const AdvertisementList = ({
  handleClickPartnerShip,
  advertisements,
  handlePartnershipDetailsClick,
  title,
  subTitle,
}: AdvertisementListProps) => {
  const theme = useTheme()

  if (!advertisements?.length) return <></>

  return (
    <>
      <Typography
        variant="headline7"
        variantColor={theme.colors.neutral[30]}
        weight={700}
      >
        {title}
      </Typography>
      <Space y="xs4" />
      <Typography variant="body3" variantColor={theme.colors.neutral[30]}>
        {subTitle}
      </Typography>
      <Space y="xs1" />
      <PartnershipCardsContainerStyled>
        {advertisements?.map((advertisement, index) => (
          <PartnershipCard
            key={`${index}-${advertisement.id}`}
            card={advertisement.card}
            id={advertisement.id}
            image={advertisement.image}
            name={advertisement.name}
            partnershipData={advertisement.partnershipData}
            onClick={() =>
              advertisement.partnershipData?.active
                ? handlePartnershipDetailsClick &&
                  handlePartnershipDetailsClick(advertisement)
                : handleClickPartnerShip(advertisement)
            }
          />
        ))}
      </PartnershipCardsContainerStyled>
      <Space y="s" />
    </>
  )
}
