import FlexBox from '@components/FlexBox'
import { LinkButtonStyled } from '@components/PartnershipDrawer/styled'
import Space from '@components/Space'
import {
  BoxAdornment,
  NotificationCard,
  ShapeIcon,
  Typography,
  Unstable,
} from '@flash-tecnologia/hros-web-ui-v2'
import { parseCurrency } from '@utils/parseCurrency'
import { AdvertisementType } from 'bff'
import { PropsWithChildren } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'

type PartnershipDrawerType = {
  openPartnershipDrawer: boolean
  setOpenPartnershipDrawer: (value: boolean) => void
  partnershipDrawerInfo: AdvertisementType | undefined
}

export const PartnershipDrawer: React.FC<
  PropsWithChildren & PartnershipDrawerType
> = ({
  openPartnershipDrawer,
  partnershipDrawerInfo,
  setOpenPartnershipDrawer,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  if (!partnershipDrawerInfo || !partnershipDrawerInfo.partnershipData) {
    return null
  }
  const { isBundle, lives, fee, productName, partnerNames, partnerWebsites } =
    partnershipDrawerInfo.partnershipData

  const isPartOfBundle =
    partnershipDrawerInfo.partnerIds?.length === 1 && isBundle

  const websites = partnerWebsites
    ?.filter(({ id }) => partnershipDrawerInfo.partnerIds?.includes(id))
    .map(({ website }) => website)

  if (fee == null || !lives) {
    return null
  }

  return (
    <Unstable.Drawer
      open={openPartnershipDrawer}
      title={`Detalhes de ${partnershipDrawerInfo.name}`}
      onClose={() => {
        setOpenPartnershipDrawer(false)
      }}
      anchor="right"
    >
      <>
        {isBundle && !isPartOfBundle && (
          <>
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[50]}
              weight={400}
            >
              Com {partnershipDrawerInfo.name}, você tem opções de pacotes de
              saúde e bem-estar com desconto para oferecer ainda mais benefícios
              aos seus colaboradores.
            </Typography>
          </>
        )}
        <Typography
          weight={700}
          variant="headline9"
          variantColor={theme.colors.neutral[30]}
        >
          Detalhes
        </Typography>
        {isBundle && !isPartOfBundle && (
          <BoxAdornment
            style={{ width: '100%' }}
            title="Parceiros contratados no pacote"
            description={
              <Typography
                weight={400}
                variant="body4"
                variantColor={theme.colors.neutral[50]}
              >
                {partnerNames?.map((name) => (
                  <FlexBox>{name}</FlexBox>
                ))}
              </Typography>
            }
            leftAdornment={
              <ShapeIcon
                size={40}
                stroke="default"
                variant="default"
                name="IconShoppingCart"
              />
            }
          />
        )}
        {isPartOfBundle && (
          <BoxAdornment
            style={{ width: '100%' }}
            title="Modo de contratação"
            description={
              <Typography
                weight={400}
                variant="body4"
                variantColor={theme.colors.neutral[50]}
              >
                Via pacote {productName}, que inclui os parceiros{' '}
                {partnerNames?.join(', ')}.
              </Typography>
            }
            leftAdornment={
              <ShapeIcon
                size={40}
                stroke="default"
                variant="default"
                name="IconShoppingCart"
              />
            }
          />
        )}
        <BoxAdornment
          style={{ width: '100%' }}
          leftAdornment={
            <ShapeIcon
              name="IconPremiumRights"
              variant="default"
              size={48}
              stroke="default"
            />
          }
          description={
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[50]}
              weight={400}
            >
              {parseCurrency(fee / 100)}
              {fee === 0 && ' (isento)'}
            </Typography>
          }
          title={'Custo por vida'}
        />
        <BoxAdornment
          style={{ width: '100%' }}
          leftAdornment={
            <ShapeIcon
              name="IconUsers"
              variant="default"
              size={48}
              stroke="default"
            />
          }
          description={
            <Typography
              variant="body4"
              variantColor={theme.colors.neutral[50]}
              weight={400}
            >
              {lives} vidas contratadas
            </Typography>
          }
          title={'Quantidade de vidas'}
        />
        {websites?.length && (
          <BoxAdornment
            style={{ width: '100%' }}
            title="Site do parceiro"
            description={
              <Typography
                weight={400}
                variant="body4"
                variantColor={theme.colors.secondary[50]}
              >
                {websites.map((site) => (
                  <FlexBox>
                    <u>
                      <a target="_blank" href={site}>
                        {site}
                      </a>
                    </u>
                  </FlexBox>
                ))}
              </Typography>
            }
            leftAdornment={
              <ShapeIcon
                size={40}
                stroke="default"
                variant="default"
                name="IconDeviceDesktop"
              />
            }
          />
        )}
      </>
      <FlexBox flexDirection="column" gap="xs5">
        <Typography
          weight={700}
          variant="headline9"
          variantColor={theme.colors.neutral[30]}
        >
          Regras de contratação
        </Typography>
        <FlexBox gap="xs5">
          <Typography
            weight={400}
            variant="body4"
            variantColor={theme.colors.neutral[50]}
          >
            Benefício contratado através de parceria
          </Typography>
          <LinkButtonStyled
            variant="primary"
            onClick={() => navigate && navigate('/marketplace')}
          >
            Flash Encanta
          </LinkButtonStyled>
        </FlexBox>
        {isBundle && (
          <Typography
            weight={400}
            variant="body4"
            variantColor={theme.colors.neutral[50]}
          >
            As regras de contratação são comuns a todos os parceiros incluídos
            no mesmo pacote.
          </Typography>
        )}
      </FlexBox>
      <NotificationCard.Root
        variant="outlined"
        type="info"
        children={
          <>
            <NotificationCard.Icon iconName="IconAlertCircle" />
            <NotificationCard.WrapperTexts>
              <NotificationCard.Title>
                Precisa de ajuda com sua contratação?
              </NotificationCard.Title>

              <NotificationCard.Subtitle>
                Para ter mais informações entre em contato através do e-mail
                empresa@flashapp.com.br
              </NotificationCard.Subtitle>
            </NotificationCard.WrapperTexts>
          </>
        }
      />
      <Space y="m" />
    </Unstable.Drawer>
  )
}
