import { BoxAdornment, Modal } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

const BundleContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`

const BoxAdornmentStyled = styled(BoxAdornment)`
  width: 100%;
  .box-adornment-flex-column {
    width: 100%;
  }
`

const StepperContainerStyled = styled.div`
  padding-left: 112px;
`

const TagsContainerStyled = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`

const PriceContainerStyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  align-content: end;
  margin-top: -15px;
`

const ModalContentStyled = styled(Modal.Content)<{
  screenHasSmallHeight: boolean
}>`
  overflow-y: auto;
  ${({ screenHasSmallHeight }) => `
    max-height: ${screenHasSmallHeight ? '350px' : 'none'};
  `}
`

export {
  BundleContainerStyled,
  BoxAdornmentStyled,
  StepperContainerStyled,
  TagsContainerStyled,
  PriceContainerStyled,
  ModalContentStyled,
}
