import styled from 'styled-components'

const FooterStyled = styled.div`
  .content-footer {
    justify-content: center;
  }
`

const ImageContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral[95]};
  border-radius: ${({ theme }) => theme.borders.radius.circular};
  padding: ${({ theme }) => theme.spacings.xs3};

  img {
    align-self: center;
    width: ${({ theme }) => theme.spacings.s};
    height: ${({ theme }) => theme.spacings.s};
  }
`

const TitleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs4};
`

const ReferencesContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs4};
`

const ImageStyled = styled.img`
  object-fit: scale-down;
`

export {
  FooterStyled,
  ImageContainerStyled,
  TitleContainerStyled,
  ReferencesContainerStyled,
  ImageStyled,
}
