import { ReactNode } from 'react'
import {
  ChildrenStyled,
  ContainerStyled,
  FooterStyled,
  HeaderStyled,
  ImageStyled,
} from './styled'

type PackageCardType = {
  children: ReactNode
  footer?: ReactNode
  header?: ReactNode
  headerBackgroundImage?: string
  height?: number | string
  width?: number | string
}

const PackageCard = ({
  children,
  footer,
  header,
  headerBackgroundImage,
  height,
  width,
}: PackageCardType) => {
  return (
    <ContainerStyled style={{ width: width, height: height }}>
      {headerBackgroundImage ? (
        <ImageStyled
          src={headerBackgroundImage}
          alt={headerBackgroundImage}
          aria-hidden="true"
        />
      ) : (
        <HeaderStyled>{header}</HeaderStyled>
      )}
      <ChildrenStyled>{children}</ChildrenStyled>
      <FooterStyled>{footer}</FooterStyled>
    </ContainerStyled>
  )
}

export default PackageCard
